import {useOktaAuth} from '@okta/okta-react';
import React, {useState, useEffect} from 'react';
import {Button, Header} from 'semantic-ui-react';
import BigButton from "./BigButton";
import './Home.css';

const Home = () => {
    const {authState, authService} = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        if (!authState.isAuthenticated) {
            setUserInfo(null);
        } else {
            authService.getUser().then((info) => {
                setUserInfo(info);
            });
        }
    }, [authState, authService]);

    const login = async () => {
        authService.login('/');
    };

    if (userInfo) {
        var name=userInfo.name;
    }

    return (
        <div className={"container"}>
            {authState.isAuthenticated && (
                <div>
                    <h1>Heureux de vous revoir {name}</h1>
                    <div className={"bigButtons"}>
                        <div className={"buttonsHome"}>
                            <BigButton link="/cartes" title={"J'accède à mes cartes"} img={"/images/illustration-connection.svg"}/>
                        </div>
                        <div className={"buttonsHome"}>
                            <BigButton link="/clients" title={"J'accède à mes clients"} img={"/images/illustration-customers.svg"}/>
                        </div>
                    </div>
                </div>
            )}

            {!authState.isAuthenticated
            && (
                <div>
                    <h1>{process.env.REACT_APP_MSG_HOME}</h1>
                    <div className={"buttonsHome"}>
                        <BigButton onClick={login} title={"Je me connecte ou je m'inscris"} img={"/images/illustration-connection.svg"}/>
                    </div>
                </div>
            )}
        </div>
    );
};
export default Home;
