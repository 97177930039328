import React, { Component } from 'react';
import { Button, Form, Message } from 'semantic-ui-react'
import {withOktaAuth, useOktaAuth} from '@okta/okta-react';
import axios from 'axios';
import Cookies from "universal-cookie";

export default withOktaAuth(class CarteFrom extends Component {

    constructor (props) {
        super(props);

        this.cookies = new Cookies();
        this.state = {
            title: '',
            errorMessage: '',
            error: false,
            isLoading: false,
            file: '',
            sub: null,
            searchId:null
        }
        this.handleChange = this.handleChange.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }

    handleChange(e) {
        this.setState({
            title: e.target.value
        })
    }

    onFileChange = (event) => {
        this.setState({
            file: event.target.files
        });
    }

    async onSubmit(e) {
        this.props.authService.getUser().then((info) => {

            this.setState({ sub: info.sub });
            let formData = new FormData();


            let searchId=this.state.sub;

            if(this.cookies.get('controlUser')){
                searchId=this.cookies.get('controlUser')[0];
            }

            for(var x = 0; x<this.state.file.length; x++) {
                formData.append('file[]', this.state.file[x])
            }

            this.setState({
                isLoading: true,
                error: false,
                errorMessage: '',
                searchId: searchId
            });

            formData.append('id', searchId);
            formData.append('name', this.state.file.name);
            formData.append('title', this.state.title);

            e.preventDefault();

            const accessToken = this.props.authState.accessToken;

            const headers = {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+accessToken
            }

            const response = axios.post(process.env.REACT_APP_API_BASE_URL + 'menu', formData, {
                headers: headers
            }).then(res => {
                const data = res.data

                if (data.errors) {
                    this.setState({
                        isLoading: false,
                        error: true,
                        errorMessage: data.errors
                    });
                } else {
                    this.setState({
                        title: '',
                        isLoading: false,
                        error: false,
                        errorMessage: ''
                    });
                    this.props.onAddition(data);
                }
            });
        });
    }


    render() {
        return (
            <Form error={this.state.error} onSubmit={this.onSubmit}>
                <Form.Field error={this.state.error}>
                    <label>Titre de la carte</label>
                    <input placeholder='ex : Carte des vins, Plat du jour...' required value={this.state.title} onChange={this.handleChange}/>
                    { this.state.error &&
                    <Message
                        error
                        header='Erreur lors de la création de la carte'
                        content={this.state.errorMessage}
                    />
                    }
                </Form.Field>
                <Form.Field error={this.state.error}>
                    <label>Fichiers (Max 5 fichiers .jpg, .png ou .pdf)</label>
                    <input type="file" name="imgCollection" onChange={this.onFileChange} multiple required/>
                    { this.state.error &&
                    <Message
                        error
                        header='Erreur lors de la création de la carte'
                        content={this.state.errorMessage}
                    />
                    }
                </Form.Field>
                <Button type='submit' color={"green"} loading={this.state.isLoading}>Ajouter la carte</Button>
            </Form>
        )
    }
});