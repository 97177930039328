import React, {Component} from 'react';
import {Button, Message, Table} from 'semantic-ui-react';
import {withOktaAuth} from '@okta/okta-react';
import CarteForm from "./CarteForm";
import Cookies from "universal-cookie";

export default withOktaAuth(class Cartes extends Component {

    constructor(props) {
        super(props);

        this.cookies = new Cookies();
        this.state = {
            cartes: null,
            isLoading: null,
            sub: null,
            searchId:null
        }
        this.onAddition = this.onAddition.bind(this);
    }

    componentDidMount() {
        this.getCartes();
    }

    onAddition(carte) {
        this.setState({
            cartes: [...this.state.cartes, carte]
        })
    }

    async getCartes() {
        this.props.authService.getUser().then((info) => {

            this.setState({sub: info.sub, isLoading: true});

            const accessToken = this.props.authState.accessToken;
            let searchId=this.state.sub;

            if(this.cookies.get('controlUser')){
                searchId=this.cookies.get('controlUser')[0];
            }

            this.setState({searchId: searchId})

            fetch(process.env.REACT_APP_API_BASE_URL + 'menus/user/' + this.state.searchId, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }).then(function (response) {
                return response.json();
            }).then(
                json => this.setState({cartes: json, isLoading: false})
            ).catch(function (error) {
                // json => this.setState({isLoading: false});
            });
        });
    }


    compareBy = (key) => {
        return function (a, b) {
            if (a[key] < b[key]) return -1;
            if (a[key] > b[key]) return 1;
            return 0;
        };
    };

    sortBy = (key) => {
        let arrayCopy = [...this.state.cartes];
        arrayCopy.sort(this.compareBy(key));
        this.setState({cartes: arrayCopy});
    };

    deleteCartes = (key, id) => {

        const accessToken = this.props.authState.accessToken;
        const response = fetch(process.env.REACT_APP_API_BASE_URL + 'menu/' + id, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        const cartes = [...this.state.cartes]
        cartes.splice(key, 1)
        this.setState({cartes})
    }

    openUrl(url) {
        const win = window.open(url, '_blank');
        if (win != null) {
            win.focus();
        }
    }


    // The render function, where we actually tell the browser what it should show
    render() {
        return (
            <div className={"container"}>
                <h1>Mes cartes</h1>
                {this.state.isLoading && <Message info header="Chargement des cartes"/>}
                {this.state.cartes &&
                <div>
                    <Table>
                        <thead id={"tableHead"}>
                            <tr>
                                {/*<th onClick={() => this.sortBy('id')}>Id</th>*/}
                                <th onClick={() => this.sortBy('title')} style={{color:process.env.REACT_APP_MAIN_COLOR}}>Titre</th>
                                <th style={{color:process.env.REACT_APP_MAIN_COLOR}}>Pages</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody id={"tableBody"}>
                        {Object.keys(this.state.cartes).map(
                            key =>
                                <tr key={key}>
                                    {/*<td>{this.state.cartes[key]['id']}</td>*/}
                                    <td>{this.state.cartes[key]['title']}</td>
                                    <td>
                                        {
                                            Object.keys(this.state.cartes[key]['files']).map(key2 =>
                                                <Button key={key2}
                                                        onClick={() => this.openUrl(process.env.REACT_APP_API_BASE_URL + this.state.cartes[key]['files'][key2]['url'])}
                                                        color="blue">{this.state.cartes[key]['files'][key2]['title']}</Button>
                                            )
                                        }
                                    </td>
                                    <td>
                                        {/*<Button color="red" onClick={() => this.deleteCartes(key, this.state.cartes[key]['id'])}>Supprimer</Button>*/}
                                        <Button color="red" onClick={() => {
                                            if (window.confirm('Êtes-vous sûr de vouloir supprimer cette carte ?')) this.deleteCartes(key, this.state.cartes[key]['id'])
                                        }}>Supprimer</Button>
                                    </td>
                                </tr>
                        )}
                        </tbody>
                    </Table>

                    <div className={"row"}>
                        <div className={"col-6 addCarte"}>
                            <h1>Ajouter une carte</h1>
                            <CarteForm onAddition={this.onAddition}/>
                        </div>
                        <div className={"col-6 qrCodePart"}>
                            <h1>Mon QR code (Cliquer dessus pour télécharger)</h1>
                            {process.env.REACT_APP_QR_CODE_CREATION_METHOD=='only-qrcode-with-company-name'
                                ? <a href={process.env.REACT_APP_API_BASE_URL + 'qrcode-v2/qr-' + this.state.searchId + '.png'} download target={'_blank'}>
                                    <img alt="" src={process.env.REACT_APP_API_BASE_URL + 'qrcode-v2/qr-' + this.state.searchId + '.png'}/>
                                </a>

                                : <a href={process.env.REACT_APP_API_BASE_URL + 'qrcode-v3/qr-' + this.state.searchId + '.png'} download target={'_blank'}>
                                    <img alt="" src={process.env.REACT_APP_API_BASE_URL + 'qrcode-v3/qr-' + this.state.searchId + '.png'} width={"70%"}/>
                                </a>
                            }
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
});