import React, {Component} from 'react';
import {Button, Message, Table} from 'semantic-ui-react';
import { withOktaAuth } from '@okta/okta-react';
import Moment from 'moment';
import './Customers.css';
import Cookies from "universal-cookie";

export default withOktaAuth(class Customers extends Component {

    // Then we add our constructor which receives our props
    constructor(props) {
        super(props);

        this.cookies = new Cookies();

        // Next we establish our state
        this.state = {
            customers: null,
            isLoading: null,
            searchId:null
        }
    }

    componentDidMount() {
        this.getCustomers();
    }

    async getCustomers() {
        this.props.authService.getUser().then((info) => {

            this.setState({sub: info.sub, isLoading: true});

            const accessToken = this.props.authState.accessToken;
            let searchId=this.state.sub;

            if(this.cookies.get('controlUser')){
                searchId=this.cookies.get('controlUser')[0];
            }
            this.setState({searchId: searchId})

            fetch(process.env.REACT_APP_API_BASE_URL + 'customer_register/' + this.state.searchId, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }).then(function (response) {
                if (response.status === 200) {
                    return response.json();
                } else {
                    window.location.href = process.env.REACT_APP_DOMAIN;
                }

            }).then(
                json => this.setState({customers: json, isLoading: false})
            ).catch(function (error) {
            });
        });
    }

    compareBy = (key) => {
        return function (a, b) {
            if (a[key] < b[key]) return -1;
            if (a[key] > b[key]) return 1;
            return 0;
        };
    };

    sortBy = (key) => {
        let arrayCopy = [...this.state.customers];
        arrayCopy.sort(this.compareBy(key));
        this.setState({customers: arrayCopy});
    };

    deleteCustomers = (key, id) => {

        const accessToken = this.props.authState.accessToken;
        const response = fetch(process.env.REACT_APP_API_BASE_URL + 'customer_register/' + id + '/' + this.state.searchId, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        const customers = [...this.state.customers]
        customers.splice(key, 1)
        this.setState({customers})
    }

    exportCsv = () => {

        const accessToken = this.props.authState.accessToken;
        const response = fetch(process.env.REACT_APP_API_BASE_URL + 'customer_register/' + this.state.searchId + '/export_csv', {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        }).then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `export-${this.state.sub}.csv`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    // The render function, where we actually tell the browser what it should show
    render() {
        return (
            <div className={"container"}>
                <div className={"headerCustomer"}>
                    <h1>Clients</h1>
                    <div><Button color="blue" onClick={() => this.exportCsv()}>Exporter les données</Button></div>
                </div>


                {this.state.isLoading && <Message info header="Chargement des clients"/>}
                {this.state.customers &&
                <div>
                    <Table>
                        <thead>
                        <tr>
                            <th onClick={() => this.sortBy('created')} style={{color:process.env.REACT_APP_MAIN_COLOR}}>Date de passage</th>
                            <th onClick={() => this.sortBy('lastName')} style={{color:process.env.REACT_APP_MAIN_COLOR}}>Prénom</th>
                            <th onClick={() => this.sortBy('firstName')} style={{color:process.env.REACT_APP_MAIN_COLOR}}>Nom</th>
                            <th onClick={() => this.sortBy('primaryPhone')} style={{color:process.env.REACT_APP_MAIN_COLOR}}>Téléphone</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.customers.length > 0 ?
                            Object.keys(this.state.customers).map(
                                key =>
                                    <tr id={this.state.customers[key]['id']} key={key}>
                                        <td>{Moment(this.state.customers[key]['created']).format('DD/MM/YYYY')}</td>
                                        <td>{this.state.customers[key]['lastName']}</td>
                                        <td>{this.state.customers[key]['firstName']}</td>
                                        <td>{this.state.customers[key]['phone']}</td>
                                        <td>
                                            <Button color="red" onClick={() => {
                                                if (window.confirm('Êtes-vous sûr de vouloir supprimer ce client ?')) this.deleteCustomers(key, this.state.customers[key]['id'])
                                            }}>Supprimer</Button>
                                        </td>
                                    </tr>
                            )
                            :
                            <tr>
                                <td colSpan={5}>Aucun résultat</td>
                            </tr>
                        }
                        </tbody>
                    </Table>
                </div>
                }
            </div>
        );
    }

});