import React from 'react';
import queryString from 'query-string';
import './Callback.css'


const Callback = (props) => {

    let url = props.location.search;
    let params = queryString.parse(url);

    var error = params.error;
    var messageError = params.error_description;

    var message = {
        title: '',
        img: '',
        message: ''
    };

    if(error=='access_denied' && messageError=='User is not assigned to the client application.'){
        var message = {
            title: 'Merci pour votre inscription !',
            img: '/images/icon-validation.svg',
            message: 'Votre compte doit être validé par un administrateur avant de pouvoir vous connecter.'
        };
    }

    // else if(url==''){
    //     return (<Redirect to={{ pathname: '/' }}/>);
    // }

    return (
        <div className={"container callback"}>
            <div>
                <h1>{message.title}</h1>
                <img src={message.img} alt="" />
                <p className={"accountValidation"}>{message.message}</p>
            </div>
        </div>
    );
};

export default Callback;
