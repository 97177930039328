import React from 'react';
import './Legals.css';

const Legals_glou_glou = () => {

    return (
        <div className="container-fluid legalsPage">
            <div className="container">
                <h1>Mentions légales</h1>
                <div className="row">
                    <div className={"col-6 legalsLeft"}>
                        <h2 style={{color: process.env.REACT_APP_MAIN_COLOR}}>Informations sur la Société</h2>
                        <p>Le présent site https://macarte.glou-glou.fr (ci-après le « Site ») est la propriété de : La
                            société
                            RHÔNE-ALPES DISTRIBUTION, Société par actions simplifiée au capital de 1500000 Euros,
                            enregistrée au
                            registre du commerce et des sociétés de LYON sous le numéro 78969426200112, dont le siège
                            principal est : <br/><br/>15 rue de genève <br/>69800 Saint-Priest<br/> Tél. 04 78 70 06
                            06</p>
                        <h2 style={{color: process.env.REACT_APP_MAIN_COLOR}}>Directeur de la publication</h2>
                        <p>Sébastien TOURNISSOUX</p>
                        <h2 style={{color: process.env.REACT_APP_MAIN_COLOR}}>Création et Gestion du Site</h2>
                        <p> IDAPP<br/>
                            45C rue de la 12e escadre d'aviation, 51100 Reims<br/>
                            contact@idapp.pro<br/>
                            Tél. : 06 66 16 91 83</p>
                        <h2 style={{color: process.env.REACT_APP_MAIN_COLOR}}>Hébergement du Site</h2>
                        <p>OVH<br/>
                            SAS au capital de 10 069 020 €<br/>
                            RCS Lille Métropole 424 761 419 00045<br/>
                            Code APE 2620Z<br/>
                            N° TVA : FR 22 424 761 419<br/>
                            Siège social : 2 rue Kellermann - 59100 Roubaix – France</p>

                        <h2 style={{color: process.env.REACT_APP_MAIN_COLOR}}>Responsabilités</h2>
                        <p>RHÔNE-ALPES DISTRIBUTION, s’efforce de tenir à jour le site https://macarte.glou-glou.fr,
                            cependant RHÔNE-ALPES DISTRIBUTION ne peut
                            garantir à tout moment, l’exhaustivité et l’exactitude des informations qui y sont
                            diffusées, ni être tenu pour responsable des suites de leur utilisation par les internautes.
                            Ce Site peut comporter des liens vers d’autres sites Internet, toutefois RHÔNE-ALPES
                            DISTRIBUTION ne peut
                            être tenu pour responsable de tout préjudice survenu des suites de l’utilisation de biens ou
                            services mis à disposition sur ces sites ou d’informations diffusées sur ceux-ci.
                            RHÔNE-ALPES DISTRIBUTION ne
                            pourra être tenu pour responsable des problèmes techniques tels que les interruptions de
                            service ou dysfonctionnements informatiques.</p>
                        <p>RHÔNE-ALPES DISTRIBUTION met à disposition gratuitement la solution Ma Carte GlouGloy qui est
                            à destination de ses clients. RHÔNE-ALPES DISTRIBUTION se réserve à tout moment le droit de
                            pouvoir supprimer un ou plusieurs utilisateurs du site et les utilisateurs acceptent sans
                            réserve cette possibilité. La clôture du compte principal chez RHÔNE-ALPES DISTRIBUTION
                            entraînera immédiatement la clôture du compte Ma Carte GlouGlou sans préavis et sans aucun
                            recours possible. Le client et utilisateur du service Ma Carte GlouGlou devra s'organiser en
                            amont de la fermeture du site pour récupérer ou sauvegarder les données.</p>

                    </div>
                    <div className={"col-6 legalsRight"}>
                        <h2 style={{color: process.env.REACT_APP_MAIN_COLOR}}>Données Personnelles</h2>
                        <p>Lorsque vous naviguez sur le site, certaines informations sur les utilisateurs peuvent être
                            recueillis de façon active (nom, prénom, adresse postale, téléphone, adresse courriel) ou de
                            façon passive (que vous n’avez pas fournies de façon active) tels le type de navigateur
                            utilisé, le nom de domaine, les pages visitées et la durée de la visite, à l’aide de divers
                            outils et moyens, dont les témoins, les marqueurs Internet et la collecte de données de
                            navigation, dans le seul but d’améliorer le site. Aucune information recueillie n’est
                            divulguée à des tiers pour fins publicitaires ou autres sauf qu’elle peut être transmise aux
                            entrepreneurs de RHÔNE-ALPES DISTRIBUTION qui collaborent à la programmation ou fournissent
                            un appui technique à l’hébergement et au fonctionnement du site. Les communications
                            effectuées par l’entremise du site pourraient ne pas être confidentielles et pourraient, par
                            conséquent, être lues ou interceptées par autrui.<br/>
                            RHÔNE-ALPES DISTRIBUTION est le responsable du traitement des données collectées sur le
                            Site.<br/>
                            L’utilisateur est notamment informé que, conformément à l’article 32 de la loi Informatique
                            et libertés du 6 janvier 1978 modifiée, les informations qu’il communique sur le Site sont
                            nécessaires pour répondre à sa demande et sont destinées aux services en charge de répondre
                            à sa demande à des fins de suivi de cette demande.<br/>
                            Conformément aux dispositions des articles 39 et 40 de la loi « Informatique et Libertés »
                            du 6 janvier 1978 modifiée, l’utilisateur bénéficie d’un droit d’accès, de rectification, de
                            mise à jour et d’effacement des informations qui le concernent, qu’il peut exercer en
                            s’adressant à information@rhonealpesdistribution.fr , ou par courrier à RHÔNE-ALPES
                            DISTRIBUTION, 15 Rue de Geneve, 69800 Saint-Priest en précisant dans l’objet du courrier «
                            Données personnelles
                            » et en joignant la copie de son justificatif d’identité.<br/><br/>

                            Vous bénéficiez également du droit de donner des directives sur le sort de vos données après
                            votre décès.<br/>
                            Conformément aux dispositions de l’article 38 de la loi « Informatique et Libertés » du 6
                            janvier 1978 modifiée, l’utilisateur peut également s’opposer, pour des motifs légitimes à
                            ce que ses données fassent l’objet d’un traitement et sans motif et sans frais, à ce que ses
                            données soient utilisées à des fins de prospection commerciale.<br/>
                            Les informations recueillies sur ce Site seront conservées pendant la durée légale de leur
                            conservation.<br/>
                            L’utilisateur du Site est tenu de respecter les dispositions de la loi Informatique et
                            libertés du 6 janvier 1978 modifiée dont la violation est passible de sanctions pénales. Il
                            doit notamment s’abstenir, s’agissant des informations nominatives auxquelles il accède, de
                            toute collecte, de toute utilisation détournée, et d’une manière générale, de tout acte
                            susceptible de porter atteinte à la vie privée ou à la réputation des personnes.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Legals_glou_glou;
