import React from 'react';
import './Nav.css';

const NavbarPublic = () => {

    return (
        <nav className="container-fluid">
            <div className="container">
                <div className="row">
                    <div className="logoPublic col-2">
                        <a href="/"><img alt="logo" src={`/images/${process.env.REACT_APP_PROJECT}/logo-project-public.svg`} className={`max-w-100 ${process.env.REACT_APP_PROJECT=='glou_glou' ? 'max-w-100-important' : ''}`}/></a>
                    </div>
                </div>
            </div>
        </nav>
    );
};
export default NavbarPublic;
