import React from 'react';
import './Footer.css';

const Footer = () => {

    return (
        <footer className="container-fluid" style={{color:process.env.REACT_APP_MAIN_COLOR}}>
            <div className="container">
                <div className="row">
                    <div className="footer-logo col-3">
                        <span>Powered by </span>
                        <a href={process.env.REACT_APP_WEBSITE} target="_blank"><img alt="logo Soredis" src={`/images/${process.env.REACT_APP_PROJECT}/logo-company.svg`} className={"max-w-100"}/></a><br />

                    </div>
                    <div className="col-9 contact">
                        <p style={{color:process.env.REACT_APP_MAIN_COLOR}}><a href={"/mentions-legales"} style={{color:process.env.REACT_APP_MAIN_COLOR}}>Mentions légales</a> - {process.env.REACT_APP_CONTACT}</p>
                    </div>
                </div>
            </div>
        </footer>

    );
};
export default Footer;
