const CLIENT_ID = process.env.CLIENT_ID || '{clientId}';
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;

// export const DOMAIN = 'https://cartelibre.fr/';
// export const DOMAIN = 'http://localhost:8080/';
// const ISSUER = 'https://dev-856764.okta.com/oauth2/default';
// const ISSUER = 'https://auth.cartelibre.fr/oauth2/ausdrgzrjIp8lxdZV4x6';
// export const API_BASE_URL = 'http://cartelibre-api:8888/';
// export const API_BASE_URL = 'https://api.cartelibre.fr/';

export default {
  oidc: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    issuer: process.env.REACT_APP_ISSUER,
    redirectUri: process.env.REACT_APP_CALLBACK,
    // redirectUri: 'https://cartelibre.fr/implicit/callback',
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  }
};
