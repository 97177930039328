import React, {Component} from 'react';
import {Button, Message, Form} from 'semantic-ui-react';
import {withOktaAuth} from '@okta/okta-react';
import './Organization.css';
import axios from "axios";
import Loading from "./Loading";

export default withOktaAuth(class Organization extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userExist: true,
            cartes: null,
            firstname: '',
            lastname: '',
            phone: '',
            agreement: false,
            oktauserid: '',
            errorMessage: '',
            error: false,
            isLoading: false,
            isLoadingCartes: true,
            registrationValidation: false,
            registrationError: false,
        }


        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getUser();
    }

    handleChange(e) {
        const target = e.target;
        const type = target.type;
        const value = target.value;
        const name = target.name;

        if (type === 'checkbox') {
            this.setState({
                [name]: target.checked
            });
        } else {
            this.setState({
                [name]: value
            });
        }
    }

    openUrl(url) {
        const win = window.open(url, '_blank');
        if (win != null) {
            win.focus();
        }
    }

    async getUser() {
        fetch(process.env.REACT_APP_API_BASE_URL + 'user/' + this.props.match.params.id + '/exist', {
            headers: {},
        }).then(response => {
            if (response.status == 200) {
                this.setState({
                    userExist: true,
                });
                this.getCartes();
            } else {
                this.setState({
                    userExist: false,
                    isLoadingCartes: false,
                });
            }
        });
    }

    async getCartes() {

        fetch(process.env.REACT_APP_API_BASE_URL + 'organization/' + this.props.match.params.id + '/cartes', {
            headers: {},
        }).then(function (response) {
            return response.json();
        }).then(
            json => this.setState({cartes: json, isLoadingCartes: false})
        ).catch(function (error) {

        });
    }

    async onSubmit(e) {
        let formData = new FormData();

        formData.append('oktauserid', this.props.match.params.id);
        formData.append('firstname', this.state.firstname);
        formData.append('lastname', this.state.lastname);
        formData.append('phone', this.state.phone);
        formData.append('agreement', this.state.agreement);

        e.preventDefault();
        this.setState({
            isLoading: true,
            error: false,
            errorMessage: ''
        });

        const headers = {}

        const response = axios.post(process.env.REACT_APP_API_BASE_URL + 'customer_register', formData, {
            headers: headers
        }).then(res => {
            const data = res.data

            this.setState({
                firstname: '',
                lastname: '',
                phone: '',
                agreement: false,
                isLoading: false,
                error: false,
                errorMessage: '',
                registrationValidation: true,
                registrationError: false,
            });
        }).catch(error => {
            this.setState({
                registrationValidation: false,
                registrationError: true,
                isLoading: false
            });
        });
    }

    render() {
        return (
            <div className={"container"}>
                {!this.state.isLoadingCartes ?
                    this.state.userExist ?
                        <>
                            {this.state.cartes && Object.keys(this.state.cartes).length > 0 &&
                            <div className={"cartesListing"}>
                                {Object.keys(this.state.cartes).map(
                                    key =>
                                        <div key={key}>
                                            <h2>{this.state.cartes[key]['title']}</h2>
                                            <div>
                                                {
                                                    Object.keys(this.state.cartes[key]['files']).map(key2 =>
                                                        <Button key={key2} className={"cartesListingButton"}
                                                                onClick={() => this.openUrl(process.env.REACT_APP_API_BASE_URL + this.state.cartes[key]['files'][key2]['url'])}
                                                                color="blue">{this.state.cartes[key]['files'].length==1 ? 'Consulter la carte': `Consulter ${this.state.cartes[key]['files'][key2]['title'].toLowerCase()}`} </Button>
                                                    )
                                                }
                                            </div>
                                        </div>
                                )}
                            </div>
                            }
                            <div className={"customerRegister"}>
                                <h3 style={{backgroundColor: process.env.REACT_APP_MAIN_COLOR}}>J'enregistre ma
                                    venue</h3>
                                <div className={"formCustomer"}>
                                    <Form error={this.state.error} onSubmit={this.onSubmit}>
                                        <Form.Field error={this.state.error}>
                                            <label>Nom</label>
                                            <input name='lastname' placeholder='ex : Dupont' required
                                                   value={this.state.lastname} onChange={this.handleChange}/>
                                            {this.state.error &&
                                            <Message
                                                error
                                                header="Erreur lors de l'enregistrement"
                                                content={this.state.errorMessage}
                                            />
                                            }
                                        </Form.Field>
                                        <Form.Field error={this.state.error}>
                                            <label>Prénom</label>
                                            <input name='firstname' placeholder='ex : Sylvain' required
                                                   value={this.state.firstname} onChange={this.handleChange}/>
                                        </Form.Field>
                                        <Form.Field error={this.state.error}>
                                            <label>Téléphone</label>
                                            <input type='tel'
                                                   pattern="^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$"
                                                   name='phone' placeholder='ex : 06 XX XX XX XX' required
                                                   value={this.state.phone} onChange={this.handleChange}/>
                                        </Form.Field>
                                        <Form.Field error={this.state.error}>
                                            <input id='agreement'
                                                   type='checkbox'
                                                   name='agreement' required
                                                   className={'checkboxAgreement'}
                                                   checked={this.state.agreement}
                                                   onChange={this.handleChange}/>
                                            <label htmlFor="agreement" className={'labelAgreement'}>Je consens à la
                                                collecte de mes données et à leur transmission, en cas de
                                                demande spécifique des autorités sanitaires compétentes dans le cadre de
                                                la
                                                recherche de « cas contacts ». Vos données seront conservées pendant 14
                                                jours à compter de leur collecte, et seront supprimées à l’issue de ce
                                                délai.</label>
                                        </Form.Field>
                                        <div className={'submit'}>
                                            <Button type='submit' color={"green"}
                                                    loading={this.state.isLoading}>Enregistrer</Button>
                                        </div>
                                        {this.state.registrationValidation &&
                                        <p className={"validationMessage"}>L'enregistrement a bien été effectué.</p>
                                        }
                                        {this.state.registrationError &&
                                        <p className={"errorMessage"}>Une erreur est survenue, veuillez réessayer
                                            ultérieurement</p>
                                        }
                                    </Form>
                                </div>
                            </div>
                        </>
                        :
                        <h2 className={"noCarte"}>Cette enseigne n'existe pas.</h2>
                    :
                    <Loading/>
                }

            </div>
        );
    }
});