import React from 'react';
import {BrowserRouter as Router, Route, Switch, withRouter, matchPath} from 'react-router-dom';
import {Security, SecureRoute, LoginCallback} from '@okta/okta-react';
import config from './config';
import Home from './Home';
import Customers from './Customers';
import Cartes from './Cartes';
import Navbar from './Navbar';
import Organization from './Organization';
import Footer from './Footer';
import FooterPublic from './FooterPublic';
import Callback from './Callback';
import Users from './Users';
import NavbarPublic from "./NavbarPublic";
import Legals_glou_glou from "./Legals_glou_glou";
import Legals_carte_libre from "./Legals_carte_libre";

const components = {
    Legals_glou_glou : Legals_glou_glou,
    Legals_carte_libre: Legals_carte_libre
};

const Main = withRouter(({location}) => {
    return (
        <Security {...config.oidc}>
            {!matchPath(location.pathname, {path: '/enseigne/:id'}) && !matchPath(location.pathname, {path: '/mentions-legales'}) ?
                <Navbar/> : <NavbarPublic/>}

            <div className={"container-fluid global"}>
                <div className={"callback-return"}>
                    <Route path="/implicit/callback" component={Callback}/>
                    <Route path="/implicit/" component={LoginCallback}/>
                </div>
                <Route path="/" exact component={Home}/>
                <SecureRoute path="/cartes" component={Cartes}/>
                <SecureRoute path="/clients" component={Customers}/>
                <SecureRoute path="/utilisateurs" component={Users}/>
                <Route exact path="/enseigne/:id" component={Organization}/>
                <Route exact path="/mentions-legales" component={components['Legals_'+process.env.REACT_APP_PROJECT]}/>
            </div>

            {!matchPath(location.pathname, {path: '/enseigne/:id'}) && !matchPath(location.pathname, {path: '/mentions-legales'}) ?
                <Footer/> : <FooterPublic/>}
        </Security>
    )
});

const App = () => (
    <Router>
        <Main/>
    </Router>

);

export default App;
