import React from 'react';
import './Footer.css';

const FooterPublic = () => {

    return (
        <footer className="container-fluid">
            <div className="container">
                <div className="row">
                    <div className="footer-logo public" style={{color:process.env.REACT_APP_MAIN_COLOR}}>
                        <span>Powered by </span>
                        <a href={process.env.REACT_APP_WEBSITE} target="_blank"><img alt="logo Soredis" src={`/images/${process.env.REACT_APP_PROJECT}/logo-company.svg`} className={"max-w-100"}/></a><br />
                    </div>
                </div>
                <div className="row publicLegals">
                    <p><a href={"/mentions-legales"} style={{color:process.env.REACT_APP_MAIN_COLOR}}>Mentions légales</a></p>
                </div>
            </div>
        </footer>
    );
};
export default FooterPublic;
