import React, {Component} from 'react';
import {Button, Message, Table} from 'semantic-ui-react';
import { withOktaAuth } from '@okta/okta-react';
import Cookies from 'universal-cookie';

export default withOktaAuth(class Users extends Component {

    // Then we add our constructor which receives our props
    constructor(props) {
        super(props);

        // Next we establish our state
        this.state = {
            users: null,
            isLoading: null,
            authAdmin: null,
            sub:null
        }
    }

    componentDidMount() {
        this.isAdmin();
    }

    async isAdmin(){
        this.props.authService.getUser().then((info) => {

            this.setState({ sub: info.sub, isLoading: true });

            const accessToken = this.props.authState.accessToken;
            fetch(process.env.REACT_APP_API_BASE_URL + 'users/'+this.state.sub, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }).then(function(response)
            {
                if(response.status===200)
                {
                    return response.json();
                }else{
                    window.location.href = process.env.REACT_APP_DOMAIN;
                }

            }).then(

                json => this.setState({ users: json, isLoading: false})
            ) .catch(function(error) {
            });
        });
    }

    compareBy = (key) => {
        return function(a, b) {
            if (a[key] < b[key]) return -1;
            if (a[key] > b[key]) return 1;
            return 0;
        };
    };

    sortBy = (key) => {
        let arrayCopy = [...this.state.users];
        arrayCopy.sort(this.compareBy(key));
        this.setState({users: arrayCopy});
    };

    deleteUser = (key, id) =>{

        const accessToken = this.props.authState.accessToken;
        const response = fetch(process.env.REACT_APP_API_BASE_URL + 'user/'+id, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        const users = [...this.state.users]
        users.splice(key, 1)
        this.setState({ users })
    }

    desactiveUser = (key, id) =>{

        const accessToken = this.props.authState.accessToken;
        const response = fetch(process.env.REACT_APP_API_BASE_URL + 'user/'+id, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        const users = [...this.state.users]
        let item = {...users[key]};
        item.group = 'not-customer';
        users[key] = item;
        this.setState({ users })
    }

    activeUser = (key, id) =>{

        const accessToken = this.props.authState.accessToken;
        const response = fetch(process.env.REACT_APP_API_BASE_URL + 'user/active/'+id, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        const users = [...this.state.users]
        let item = {...users[key]};
        item.group = 'customer';
        users[key] = item;
        this.setState({ users })
    }

    controlUser = (key, id, organization) =>{
        const cookies = new Cookies();
        cookies.set('controlUser', [id, organization], { path: '/' });
        window.location.href="/cartes";
    }


    // The render function, where we actually tell the browser what it should show
    render() {
        return (
            <div className={"container"}>
                <h1>Utilisateurs</h1>
                {this.state.isLoading && <Message info header="Chargement des Utilisateurs" />}
                {this.state.users &&
                <div>
                    <Table>
                        <thead>
                        <tr>
                            <th onClick={() => this.sortBy('created')} style={{color:process.env.REACT_APP_MAIN_COLOR}}>Date de création</th>
                            <th onClick={() => this.sortBy('organization')} style={{color:process.env.REACT_APP_MAIN_COLOR}}>Enseigne</th>
                            <th onClick={() => this.sortBy('siret')} style={{color:process.env.REACT_APP_MAIN_COLOR}}>SIRET</th>
                            <th onClick={() => this.sortBy('postalAddress')} style={{color:process.env.REACT_APP_MAIN_COLOR}}>Code postal</th>
                            <th onClick={() => this.sortBy('lastName')} style={{color:process.env.REACT_APP_MAIN_COLOR}}>Prénom</th>
                            <th onClick={() => this.sortBy('firstName')} style={{color:process.env.REACT_APP_MAIN_COLOR}}>Nom</th>
                            <th onClick={() => this.sortBy('primaryPhone')} style={{color:process.env.REACT_APP_MAIN_COLOR}}>Téléphone</th>
                            <th onClick={() => this.sortBy('email')} style={{color:process.env.REACT_APP_MAIN_COLOR}}>Email</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.keys(this.state.users).map(
                            key =>
                                <tr id={this.state.users[key]['id']} key={key}>
                                    <td>{this.state.users[key]['created']}</td>
                                    <td>{this.state.users[key]['organization']}</td>
                                    <td>{this.state.users[key]['siret']}</td>
                                    <td>{this.state.users[key]['postalAddress']}</td>
                                    <td>{this.state.users[key]['lastName']}</td>
                                    <td>{this.state.users[key]['firstName']}</td>
                                    <td>{this.state.users[key]['primaryPhone']}</td>
                                    <td>{this.state.users[key]['email']}</td>
                                    <td>
                                        {this.state.users[key]['group']==='not-customer' &&<Button color="green" onClick={() => { if (window.confirm('Êtes-vous sûr de vouloir activer cet utilisateur ?')) this.activeUser(key, this.state.users[key]['id']) } }>Activer</Button>}
                                        {this.state.users[key]['group']==='customer' &&<Button color="red" onClick={() => { if (window.confirm('Êtes-vous sûr de vouloir désactiver cet utilisateur ?')) this.desactiveUser(key, this.state.users[key]['id']) } }>Désactiver</Button>}
                                    </td>
                                    <td>
                                        <Button color="blue" onClick={() => { this.controlUser(key, this.state.users[key]['id'], this.state.users[key]['organization']) }}>Contrôler</Button>
                                    </td>
                                </tr>
                        )}
                        </tbody>
                    </Table>
                </div>
                }
            </div>
        );
    }

});