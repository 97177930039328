import React from 'react';
import {
    Link
} from "react-router-dom";
import './BigButton.css';

const BigButton = (props) => {

    if(!props.onClick){
        return (
            <Link to={props.link}>
                <div className={"ButtonHome max-w-100"}>
                    <p>{props.title}</p>
                    <img alt="" src={props.img} className={"max-w-100"}/>
                </div>
            </Link>
        );
    }else {
        return (
            <div className={"ButtonHome max-w-100"} onClick={props.onClick}>
                <p>{props.title}</p>
                <img alt="" src={props.img} className={"max-w-100"}/>
            </div>
        );
    }
};
export default BigButton;
