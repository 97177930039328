import React from 'react';
import './Loading.css';

const Loading = (props) => {
    return (
            <div className={'loading'}>
                <img alt="Chargement" src={'/images/loading.gif'} className={"max-w-100"}/>
            </div>
        )
}

export default Loading;