import {useOktaAuth} from '@okta/okta-react';
import React, {useState, useEffect} from 'react';
import {Button} from 'semantic-ui-react';
import './Nav.css';
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";

const Navbar = () => {
    const {authState, authService} = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);
    const [authAdmin, setAuthAdmin] = useState(null);
    const login = async () => authService.login('/');
    const logout = async () => authService.logout('/').then(cookies.remove('controlUser'));
    const cookies = new Cookies();

    function controlLogout(){
        cookies.remove('controlUser');
        window.location.href="/cartes";
    }

    useEffect(() => {
        if (!authState.isAuthenticated) {
            setUserInfo(null);
        } else {
            authService.getUser().then((info) => {
                setUserInfo(info);
                var sub = info.sub;

                const accessToken = authState.accessToken;
                fetch(process.env.REACT_APP_API_BASE_URL + 'user/' + sub + '/is_admin', {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }).then(
                    response => response.json()
                ).then(
                    json => setAuthAdmin(json.admin)
                ).catch();
            });

        }
    }, [authState, authService]);

    return (
        <nav className="container-fluid">
            {cookies.get('controlUser') &&
            <div className="bannerControl">
                <div className="container">
                    <div className="bannerFlex">
                        <p>Vous êtes actuellement sur le compte de {cookies.get('controlUser')[1]}</p>
                        <Button color={"blue"} onClick={controlLogout}>
                            Se déconnecter du compte {cookies.get('controlUser')[1]}
                        </Button>
                    </div>
                </div>
            </div>
            }
            <div className="container">
                <div className="row">
                    <div className="logo col-2">
                        <a href="/"><img alt="logo" src={`/images/${process.env.REACT_APP_PROJECT}/logo-project.svg`}
                                         className={`max-w-100 ${process.env.REACT_APP_PROJECT == 'glou_glou' ? 'max-w-100-important' : ''}`}/></a>
                    </div>
                    <ul className="navList col-10">
                        {authState.isAuthenticated &&
                        <>
                            <li className="navItem"><a href="/cartes" className="item">Mes cartes</a></li>
                            <li className="navItem"><a href="/clients" className="item">Mes clients</a></li>
                        </>
                        }
                        {authState.isAuthenticated && authAdmin == 1 &&
                        <li className="navItem"><a href="/utilisateurs" className="item">Utilisateurs</a></li>}
                        {!authState.isAuthenticated && <li className="navItem" onClick={login}>
                            <Button id="login-button" className="connection" onClick={login}>
                                <img alt="Connexion" src="/images/icon-connection.svg"/>Connexion
                            </Button>
                        </li>}
                        <li className="navItem">
                            <Button id="login-button" className="deconnection" onClick={logout}>
                                Déconnexion
                            </Button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
